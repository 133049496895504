<template>
  <div class="report-component-wrapper" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item - 1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Поля</v-tab>
                <v-tab>Відбір</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card class="field-card field-card-header">
                        <div class="field-row font-weight-medium">
                          <div></div>
                          <div>Поле</div>
                          <div>Назва</div>
                          <div>Ширина</div>
                          <div>Підсумки</div>
                          <div>
                            <v-menu bottom left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" small>
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list nav dense>
                                <v-list-item @click="addElement">
                                  <v-list-item-icon>
                                    <v-icon size="20" color="success" class="mr-2">mdi-plus-circle-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Додати поле
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="addGroupElement">
                                  <v-list-item-icon>
                                    <v-icon size="20" color="secondary" class="mr-2">mdi-plus-circle-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Додати групу
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                      </v-card>

                      <draggable
                          :animation="200"
                          :group="'description'"
                          :disabled="false"
                          :ghostClass="'ghost'"
                          v-model="fields"
                          @start="drag = true"
                          @end="drag = false"
                      >
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                          <v-card
                              v-for="item in fields"
                              :key="item.row_num"
                              class="field-card"
                              :class="{'pb-2': item.items}"
                              :disabled="!item.show"
                          >
                            <div class="field-row">
                              <div>
                                <v-checkbox color="success" hide-details :ripple=false
                                            class="ml-3 mt-0 pt-1 always-enable" v-model="item.show"/>
                              </div>
                              <div>
                                <v-select :items="field_select" hide-details single-line
                                          style="font-size: .8rem" dense v-if="!item.group"
                                          class="mt-0 table-small-select"
                                          color="success"
                                          v-model="item.value"
                                          required
                                          :class="{'input-error': !item.value}"
                                          :rules="[v => !!v || 'Це поле є обов’язковим']"
                                          @change="onItemValueChange($event, item)"
                                />
                                <v-chip color="grey lighten-2" label small v-else>
                                  Група
                                </v-chip>
                              </div>
                              <div>
                                <input v-model="item.name" :class="{'input-error': !item.name}"/>
                              </div>
                              <div>
                                <input v-model.number="item.width" v-if="!item.group"
                                       v-integer
                                       :class="{'input-error': !item.width}"/>
                              </div>
                              <div>
                                <v-checkbox color="success" hide-details :ripple=false
                                            class="ml-3 mt-0 pt-1" v-model="item.agg"/>
                              </div>
                              <div>
                                <v-menu
                                    bottom
                                    left
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list nav dense>
                                    <v-list-item @click="addSubElement(item)" v-if="item.group">
                                      <v-list-item-icon>
                                        <v-icon size="20" color="success" class="mr-2">mdi-plus-circle-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title>
                                        Додати елемент
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item @click="deleteElement(item)">
                                      <v-list-item-icon>
                                        <v-icon size="20" color="error">mdi-delete-circle-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title>
                                        Вилучити
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>

                            <div class="field-group-row ml-3 my-2" v-if="item.items">
                              <draggable
                                  :animation="200"
                                  :group="'description'"
                                  :disabled="false"
                                  :ghostClass="'ghost'"
                                  v-model="item.items"
                                  @start="drag = true"
                                  @end="drag = false"
                              >
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                  <div v-for="subrow in item.items"
                                       :key="subrow.row_num">
                                    <div class="field-row mb-2 grey lighten-4">
                                      <div>
                                        <v-checkbox color="secondary" hide-details :ripple=false
                                                    class="ml-4 mt-0 pt-1" v-model="subrow.show"/>
                                      </div>
                                      <div>
                                        <v-select :items="field_select" hide-details single-line
                                                  style="font-size: .8rem" dense
                                                  class="mt-0 table-small-select"
                                                  color="success"
                                                  required
                                                  :class="{'input-error': !subrow.value}"
                                                  :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  @change="onItemValueChange($event, subrow)"
                                                  v-model="subrow.value"/>
                                      </div>
                                      <div>
                                        <input v-model="subrow.name" :class="{'input-error': !subrow.name}"/>
                                      </div>
                                      <div>
                                        <input v-model.number="subrow.width" v-integer
                                               :class="{'input-error': !subrow.width}"/>
                                      </div>
                                      <div>
                                        <v-checkbox color="success" hide-details :ripple=false
                                                    class="ml-3 mt-0 pt-1" v-model="subrow.agg"/>
                                      </div>
                                      <div>
                                        <v-menu
                                            bottom
                                            left
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                small
                                            >
                                              <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                          </template>
                                          <v-list nav dense>
                                            <v-list-item @click="deleteSubElement(item.items, subrow)">
                                              <v-list-item-icon>
                                                <v-icon size="20" color="error">mdi-delete-circle-outline</v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-title>
                                                Вилучити
                                              </v-list-item-title>
                                            </v-list-item>
                                          </v-list>
                                        </v-menu>
                                      </div>
                                    </div>
                                  </div>
                                </transition-group>
                              </draggable>
                            </div>
                            <div v-if="item.group && !item.items.length">
                              <div class="mx-4 mt-1 pa-3 grey lighten-4 text-center">
                                Відсутні елементи у групі
                              </div>
                            </div>
                          </v-card>
                        </transition-group>
                      </draggable>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row class="dense-wrapper pb-1 pt-2">
                    <v-col cols="12" md="12">
                      <v-select :items="indicators_select" hide-details filled label="Вид показника"
                                v-model="filters.indicator_id"
                                required
                                :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="filters.indicator_id ? '' : 'req-star'"
                                clearable color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <AddressElementSelect
                          label="Населений пункт" v-model="filters.city_id"
                          :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect
                          label="Район міста" v-model="filters.city_area_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="city-area"
                      />
                    </v-col>
                    <v-col cols="12" v-if="filters.city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="filters.streets" multiple
                          :parent_id="filters.city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                      />
                    </v-col>
                    <v-col cols="12" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="filters.streets" multiple
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="street"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select hide-details filled v-model="filters.disable_filter"
                                :items="flat_status_select" label="Стан абонента"
                                clearable color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch
                          v-model="filters.charge_and_balances_by_service"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.charge_and_balances_by_service ? 'Залишки та нарахування по послузі (включено)' : 'Залишки та нарахування по послузі (виключено)'"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="dense-wrapper pb-1 pt-2">
                    <v-col cols="12" md="6">
                      <v-switch
                          v-model="filters.without_items"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.without_items ? 'Без детальних даних (включено)' : 'Без детальних даних (виключено)'"
                          @change="onFullWidthChange"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch
                          v-model="filters.auto_width"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.auto_width ? 'По ширині сторінки (включений)' : 'По ширині сторінки (виключений)'"
                          @change="onFullWidthChange"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <draggable
                          :animation="200"
                          :group="'description'"
                          :disabled="false"
                          :ghostClass="'ghost'"
                          v-model="grouping"
                          @start="drag = true"
                          @end="drag = false"
                      >
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                          <v-card
                              v-for="(item, idx) in grouping"
                              :key="`gr-idx-${idx}`"
                              style="display: flex; align-items: center"
                              class="ma-2 py-0"
                              elevation="3"
                          >
                            <div style="flex: 0 0 60px">
                              <v-checkbox color="success" class="ml-3 mt-0 pt-5" v-model="item.on"/>
                            </div>
                            <div style="flex: 1; font-weight: 400; font-size: .96rem">
                              {{ item.text }}
                            </div>
                            <div style="flex: 0 0 60px">
                              <v-menu v-model="item.menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                  <div
                                      :style="`background-color: ${item.color}`"
                                      :class="{'color-picker-open': item.menu, 'color-picker-close': !item.menu}" v-on="on" />
                                </template>
                                <v-card>
                                  <v-card-text class="pa-0">
                                    <v-color-picker v-model="item.color"
                                                    flat mode="hexa"
                                    />
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </div>
                          </v-card>
                        </transition-group>
                      </draggable>
                    </v-col>

                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page"
         :class="{'page-a4-landscape-auto': filters.auto_width, 'page-a4-landscape-auto-full': !filters.auto_width}"
         style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report" :style="`${filters.auto_width ? `transform: scale(${zoom}); transform-origin: top left;` : ''}`"
           style="height: 100%">
        <table :class="{'transparent-header': !column_object.length}">
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Загальний звіт з групуванням)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead id="header">
          <template>
            <tr v-if="column_object.length">
              <th
                  v-for="(first_header_row, idx) in column_object[0]" :key="`first-header-${idx}`"
                  :rowspan="first_header_row.row_span || 0"
                  :colspan="first_header_row.col_span || 0"
                  :style="`min-width: ${first_header_row.width === 'auto' ? 'auto' : `${first_header_row.width}px`}; width: ${first_header_row.width === 'auto' ? 'auto' : `${first_header_row.width}px`} !important;`"
              >
                {{ first_header_row.name }}
              </th>
            </tr>
            <tr v-else>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr v-if="(column_object[1] || []).length">
              <th
                  v-for="(second_header_row, idx) in column_object[1]" :key="`second-header-${idx}`"
                  :rowspan="second_header_row.row_span || 0"
                  :colspan="second_header_row.col_span || 0"
                  :style="`min-width: ${second_header_row.width === 'auto' ? 'auto' : `${second_header_row.width}px`}; width: ${second_header_row.width === 'auto' ? 'auto' : `${second_header_row.width}px`} !important;`"
              >
                {{ second_header_row.name }}
              </th>
            </tr>
          </template>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :style="`background: ${filters.without_items && item.level === max_lvl ? 'white': bg_colors[item.level]}`">
              <template v-for="(col, col_idx) in column_object[3]">
                <template v-if="col.first">
                  <td class="grow" :key="`${item.index}-${col_idx}`"
                      :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
                      :colspan="col.col_span"
                  >
                    <v-icon size="14" class="mr-1" color="grey darken-3"
                            @click.stop="showRows(item.class, `button-${idx}`)"
                            :id="`button-${idx}`"
                            v-if="!(filters.without_items && item.level === max_lvl)"
                    >
                      {{ getCollapseIconNew(item.level) }}
                    </v-icon>
                    {{ item.group_value }}
                  </td>
                </template>
                <template v-else>
                  <template v-if="col.agg">
                    <td :key="`${item.index}-${col_idx}`"
                        :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                    >
                      {{ item[col.value] | formatNumber }}
                    </td>
                  </template>
                  <template v-else>
                    <td :key="`${item.index}-${col_idx}`"
                        :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                    >
                    </td>
                  </template>
                </template>
              </template>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <template v-for="(col, col_idx) in column_object[2]">
                <template v-if="col.agg">
                  <td :key="`${item.index}-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                    {{ item[col.value] | formatNumber }}
                  </td>
                </template>
                <template v-else>
                  <td :key="`${item.index}-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                    {{ item[col.value] }}
                  </td>
                </template>
              </template>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <template v-for="(col, col_idx) in column_object[2]">
                <template v-if="col.agg">
                  <td :key="`${subitem.index}-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                    {{ subitem[col.value] | formatNumber }}
                  </td>
                </template>
                <template v-else>
                  <td :key="`${item.index}-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                    {{ subitem[col.value] }}
                  </td>
                </template>
              </template>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0" style="text-align: center">
            <template v-for="(col, col_idx) in column_object[3]">
              <template v-if="col.first">
                <td class="grow" :key="`total-${col_idx}`"
                    style="font-weight: 500; text-align: right;"
                    :colspan="col.col_span"
                >
                  Всього:
                </td>
              </template>
              <template v-else>
                <template v-if="col.agg">
                  <td :key="`total-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                    {{ getTotal(col.value) | formatNumber }}
                  </td>
                </template>
                <template v-else>
                  <td :key="`total-${col_idx}`"
                      :style="`min-width: ${col.width === 'auto' ? 'auto' : `${col.width}px`}; width: ${col.width === 'auto' ? 'auto' : `${col.width}px`} !important;`"
                  >
                  </td>
                </template>
              </template>
            </template>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {mapGetters} from "vuex";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {buildingTypes_select} from "@/utils/icons"

import draggable from "vuedraggable";

export default {
  name: "universal_general_report_by_grouping",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import('@/components/autocomplite/Checker'),
    draggable
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll',
          indicators_select: 'getFlatIndicatorTypes',
          settings: 'getGeneralSettings'
        }
    ),
    bg_colors() {
      return this.grouping.filter(i => i.on).map(item => item.color || '#f8f8f8')
    }
  },
  data() {
    return {
      drag: false,
      filters: {
        checker_id: null,
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        streets: [],
        disable_filter: 'all',
        indicator_id: null,
        auto_width: true,
        without_items: true
      },
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      field_select: [
        {text: 'Об’єднана громада', value: 'amalgamated_hromada_name', width: '120', agg: false},
        {text: 'Населений пункт', value: 'city_name', width: '120', agg: false},
        {text: 'Район міста', value: 'city_area_name', width: '120', agg: false},
        {text: 'Вулиця', value: 'street_name', width: '150', agg: false},
        {text: 'Послуга', value: 'service_name', width: '250', agg: false},
        {text: 'Норм. показ.', value: 'indicator_value', width: '60', agg: true},
        {text: 'К-сть абон. (усіх)', value: 'flat_counter_all', width: '90', agg: true},
        {text: 'К-сть абон. (акт.)', value: 'flat_counter_active', width: '90', agg: true},
        {text: 'К-сть абон. (зак.)', value: 'flat_counter_close', width: '90', agg: true},
        {text: 'Об’єм', value: 'square', width: '90', agg: true},
        {text: 'Об’єм по нормі', value: 'volume_normative', width: '90', agg: true},
        {text: 'Об’єм по ліч.', value: 'volume_counter', width: '90', agg: true},
        {text: 'Об’єм по буд. ліч.', value: 'volume_building_counter', width: '90', agg: true},
        {text: 'К-сть договорів', value: 'contract_count', width: '60', agg: true},
        {text: 'Сальдо на {date_start}', value: 'start_balance', width: '90', agg: true},
        {text: 'Сальдо Дт на {date_start}', value: 'start_balance_plus', width: '90', agg: true},
        {text: 'Сальдо Кт на {date_start}', value: 'start_balance_minus', width: '90', agg: true},
        {text: 'Нараховано', value: 'charge', width: '90', agg: true},
        {text: 'Знято', value: 'removal', width: '90', agg: true},
        {text: 'Перераховано', value: 'recalculation', width: '90', agg: true},
        {text: 'Оплачено', value: 'pay', width: '90', agg: true},
        {text: 'Сальдо на {date_end}', value: 'end_balance', width: '90', agg: true},
        {text: 'Сальдо Дт на {date_end}', value: 'end_balance_plus', width: '90', agg: true},
        {text: 'Сальдо Кт на {date_end}', value: 'end_balance_minus', width: '90', agg: true},
      ],
      default_fields: [
        {
          "name": "Група",
          "width": 250,
          "first": true,
          "group": false,
          "value": 'street_name',
          "agg": false,
          "show": true
        },
        {
          "name": 'К-сть абонентів',
          "width": 'auto',
          "group": true,
          "items": [
            {"name": 'Усіх', "width": 74, "value": 'flat_counter_all', "agg": true, "show": true},
            {"name": 'Діючих', "width": 80, "value": 'flat_counter_active', "agg": true, "show": true},
            {"name": 'Закритих', "width": 80, "value": 'flat_counter_close', "agg": true, "show": true},
          ],
          "show": true
        },
        {
          "name": 'Об’єми',
          "width": 'auto',
          "group": true,
          "items": [
            {"name": 'По нормі', "width": 90, "value": 'volume_normative', "agg": true, "show": true},
            {"name": 'По ліч.', "width": 90, "value": 'volume_counter', "agg": true, "show": true},
            {"name": 'В т.ч буд.', "width": 90, "value": 'volume_building_counter', "agg": true, "show": true},
          ],
          "show": true
        },
        {
          "name": "К-ть меш-канців",
          "width": 60,
          "group": false,
          "agg": true,
          "value": "indicator_value",
          "show": true
        },
        {
          "name": "Борг на {start_date}",
          "width": 90,
          "group": false,
          "agg": true,
          "value": "start_balance",
          "show": true
        },
        {
          "name": "Нараховано",
          "width": 90,
          "group": false,
          "agg": true,
          "value": "charge",
          "show": true
        },
        {
          "name": "Перерахунок",
          "width": 90,
          "group": false,
          "agg": true,
          "value": "recalculation",
          "show": true
        },
        {
          "name": "Оплачено",
          "width": 90,
          "group": false,
          "agg": true,
          "value": "pay",
          "show": true
        },
        {
          "name": "Борг на {end_date}",
          "width": 90,
          "group": false,
          "agg": true,
          "value": "end_balance",
          "show": true
        }
      ],
      operator_select: [
        { text: 'Дорівнює', value: '=' },
        { text: 'Не дорівнює', value: '!=' },
        { text: 'Більше рівне', value: '>=' },
        { text: 'Менше рівне', value: '<=' },
        { text: 'Більше', value: '>' },
        { text: 'Менше', value: '<' },
        { text: 'Між', value: 'between' },
      ],
      // post_filter: [
      //   { active: false, name: 'Тариф', column: 'tariff', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Норм. показ.', column: 'indicator_value', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'К-сть договорів', column: 'contract_count', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на поч.', column: 'start_balance', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на поч. Дт', column: 'start_balance_plus', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на поч. Кт', column: 'start_balance_minus',operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Нараховано', column: 'charge', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Знято', column: 'removal', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Перераховано', column: 'recalculation', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Оплачено', column: 'pay', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на кін.', column: 'end_balance', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на кін. Дт', column: 'end_balance_plus', operator: '=',  value_from: 0, value_to: 0},
      //   { active: false, name: 'Сальдо на кін. Кт', column: 'end_balance_minus', operator: '=',  value_from: 0, value_to: 0},
      // ],
      fields: [],
      grouping: [
        {text: 'Послуги', value: 'service_id', on: true, color: '#d9d9d9', menu: false},
        {text: 'Об’єднані громади', value: 'amalgamated_hromada_id', on: true, color: '#d9d9d9', menu: false},
        {text: 'Населений пункт', value: 'city_id', on: true, color: '#e1e1e1', menu: false},
        {text: 'Район міста', value: 'city_area_id', on: false, color: '#e3e3e3', menu: false},
        {text: 'Вулиця', value: 'street_id', on: false, color: '#e8e8e8', menu: false},
        {text: 'Вид житла', value: 'building_type', on: false, color: '#eeeeee', menu: false},
      ],
      column_object: [],
      buildingTypes_select,
      flat_status_select: [
        {text: 'Усі', value: 'all'},
        {text: 'Актуальні', value: 'active'},
        {text: 'Закриті', value: 'closed'},
      ]
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.filters.checker_id = (payload || {}).value || null
    },
    generate_rows(items, max_lvl, by_grouping) {
      this.items = Object.freeze(items)
      this.max_lvl = max_lvl
      this.by_grouping = by_grouping
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end && this.filters.indicator_id) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => {
          return {value: item.value, color: item.color || null}
        })
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping, columns: this.fields})
        }
        reportAPI.universal_general_report_by_grouping_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `universal_report_by_flat_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
              this.$emit('xlsx_loading_status', false)
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end && this.filters.indicator_id) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => {
          return {value: item.value, color: item.color || null}
        })
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {grouping: grouping, columns: this.fields})
        }
        reportAPI.universal_general_report_by_grouping_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
              this.$emit('email_loading_status', false)
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end && this.filters.indicator_id) {
        const field_errors = this.checkFields()

        if (field_errors.length) {
          let error_text = ''
          field_errors.forEach(i => {
            error_text += `${i}'`
          })
          this.$store.commit(ALERT_SHOW, {text: error_text, color: 'error'})
          return
        }

        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => {
          return {value: item.value, color: item.color || null}
        })
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping, columns: this.fields}),
        }
        reportAPI.universal_general_report_by_grouping(payload)
            .then(response => response.data)
            .then(data => {
              this.items = []
              this.expand = false
              this.by_grouping = false
              this.max_lvl = 0
              this.column_object = data.column_object
              this.generate_rows(data.rows, data.max_level, data.by_grouping)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl)
                // this.$nextTick(() => {
                this.getOnFullPage()
                // })
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період та нормативний показник - мають бути заповнений',
          color: 'error'
        })
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const header = document.getElementById('header')
      const first_row = header.getElementsByTagName('tr')[0]
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    addSubElement(payload) {
      if (!payload.items) {
        payload.items = []
      }
      payload.items.push(
          {
            "name": "",
            "width": 0,
            "group": false,
            "value": '',
            "agg": false,
            "show": false,
            "row_num": this.getMaxRowNum()
          },
      )
    },
    addElement() {
      this.fields.push(
          {
            "name": "",
            "width": 0,
            "group": false,
            "value": '',
            "agg": false,
            "show": false,
            "row_num": this.getMaxRowNum()
          },
      )
    },
    addGroupElement() {
      this.fields.push(
          {
            "name": "",
            "width": 0,
            "group": true,
            "items": [],
            "value": '',
            "agg": false,
            "show": false,
            "row_num": this.getMaxRowNum()
          },
      )
    },
    deleteElement(payload) {
      this.fields.splice(this.fields.indexOf(payload), 1)
    },
    deleteSubElement(iter, payload) {
      iter.splice(iter.indexOf(payload), 1)
    },
    setRowNum(payload) {
      const new_array = JSON.parse(JSON.stringify(payload))
      let row_idx = 0

      new_array.forEach(item => {
        row_idx += 1

        item.row_num = row_idx

        if ((item.items || []).length) {
          item.items.forEach(subitem => {
            row_idx += 1

            subitem.row_num = row_idx
          })
        }
      })
      return new_array
    },
    getMaxRowNum() {
      const new_array = this.fields
      let row_idx = 0

      new_array.forEach(item => {
        row_idx += 1
        if ((item.items || []).length) {
          item.items.forEach(() => {
            row_idx += 1
          })
        }
      })
      return row_idx + 1
    },
    onItemValueChange(select_payload, item) {
      this.$nextTick(() => {
        const selected_row = this.field_select.find(i => i.value === select_payload)

        item.name = selected_row.text
        item.width = selected_row.width
        item.agg = selected_row.agg
        item.show = true
      })
    },
    checkFields() {
      const errors = []
      this.fields.forEach((item, index) => {
        if (!item.group && !item.value) {
          errors.push(`Колонка №${index + 1}: відсутнє поле`)
        }
        if (!item.name) {
          errors.push(`Колонка №${index + 1}: не заповнена назва поля`)
        }
        if (!item.group && !item.width) {
          errors.push(`Колонка №${index + 1}: відсутня ширина поля`)
        }

        if (item.group) {
          const sub = item.items || []

          sub.forEach(s => {
            if (!s.value) {
              errors.push(`Група ${item.name}: відсутнє поле`)
            }
            if (!s.name) {
              errors.push(`Група ${item.name}: не заповнена назва поля`)
            }
            if (!s.width) {
              errors.push(`Група ${item.name}: відсутня ширина поля`)
            }
          })
        }
      })

      if (this.fields.length) {
        const first_row = this.fields[0]

        if (first_row.group) {
          const items = first_row.items || []
          if (items[0].agg) {
            errors.push(`Перша колонка не може мати підсумки`)
          }
        } else {
          if (first_row.agg) {
            errors.push(`Перша колонка не може мати підсумки`)
          }
        }

      }

      return errors
    },
    onFullWidthChange(payload) {
      this.$nextTick(() => {
        if (payload) {
          this.getOnFullPage()
        }
      })
    }
  },
  created() {
    reportAPI.report_settings("universal_general_report_by_grouping")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })

          this.$store.dispatch(FETCH_FLAT_INDICATOR_TYPES)

          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              const row = server_filters.grouping.find(i => i.value === g.value)
              g.on = !!row
              if (row) {
                g.color = row.color
              }
            })
          }
          if (server_filters['columns'] && this.fields) {
            this.fields = this.setRowNum(server_filters['columns'])
          }
          if (!this.fields.length) {
            this.fields = this.setRowNum(this.default_fields)
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            if (!this.filters.indicator_id) {
              this.filters.indicator_id = this.settings?.general_indicator?.value || null
            }
            this.getOnFullPage()
          })
        })
  }
}
</script>

<style lang="scss" scoped>
.field-card {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  &.field-card-header {
    background-color: #e5e5e5;
  }

  &:nth-child(1) {
    margin-top: 4px;
  }

  .field-row {
    display: flex;
    flex-wrap: nowrap;
    font-size: .76rem;
    align-items: center;

    & > div {
      padding: 3px;
    }

    & > div {
      & > input {
        text-align: inherit;
        padding: 2px 4px;
        outline: none;
        width: 92%;
        height: 100%;
        border-bottom: 1px solid var(--v-success-base);
      }

      & > .input-error {
        border-bottom: 1px solid var(--v-error-base);
      }

      &:nth-child(1) {
        flex: 0 0 60px;
      }

      &:nth-child(2) {
        flex: 0 0 170px;
        overflow: hidden;
      }

      &:nth-child(3) {
        flex: 1;
      }

      &:nth-child(4) {
        flex: 0 0 70px;
      }

      &:nth-child(5) {
        flex: 0 0 60px;
      }

      &:nth-child(6) {
        flex: 0 0 30px;
      }
    }
  }

  .always-enable {
    pointer-events: all !important;
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important;
  }
}


.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.table-small-select {
  :deep(input) {
    line-height: 12px !important;
    padding: 0 !important;
  }

  :deep(.v-select__selection.v-select__selection--comma) {
    margin: 0 !important;
  }

  :deep(.v-input__icon) {
    width: 20px !important;
    height: 20px !important;
  }

  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-success-base);
  }
}

.table-small-select.input-error {
  :deep(.v-input__control > .v-input__slot:before) {
    border-color: var(--v-error-base) !important;
  }
}
.post-filter {
  & > div {
    padding: 4px;

    & > input {
      text-align: inherit;
      padding: 2px 4px;
      outline: none;
      width: 92%;
      height: 100%;
      border-bottom: 1px solid var(--v-success-base);
    }

    & > .input-error {
      border-bottom: 1px solid var(--v-error-base);
    }
  }
}
.color-picker-open {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transition: border-radius 200ms ease-in-out;
}
.color-picker-close {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  transition: border-radius 200ms ease-in-out;
}
</style>